import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import rightArrow from "../../Assets/images/arrow_forward.svg";
// import leftArrow from "../../Assets/images/arrow_back.svg";
import "./style.css";

export default function PriceBox({
  updatePriceBox,
  nextUrl,
  updatePageCount = "",
  addBtnFunc,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  const NavigateUrls = [
    "/cover",
    "/firstpage",
    "/layout",
    "/addins",
    "/dates",
    "/review",
  ];
  const urlIndex = NavigateUrls.indexOf(currentRoute);

  const today = new Date();
  const endDate = new Date(today); // Clone today's date
  endDate.setDate(today.getDate() + 10); // Calculate the max date = today+10days

  // Construct the formatted date string
  const maxDateFormat = "";
  const formattedPrice = 0;

  const [pageCount, setPageCount] = useState("2");
  const [maxPageCount, setMaxPageCount] = useState("242");
  const [price, setPrice] = useState(70);
  const [maxDate, setMaxDate] = useState(endDate);

  const [formatedPrice, setFormatedPrice] = useState("");
  const [formatedMaxDate, setFormatedMaxDate] = useState("");

  const [nextBtnEnable, setNextBtnEnable] = useState(true);
  const [backBtnEnable, setBackBtnEnable] = useState(true);

  var CoverData = JSON.parse(sessionStorage.getItem("Cover"));
  var FirstPageData = JSON.parse(sessionStorage.getItem("FirstPage"));
  var LayoutData = JSON.parse(sessionStorage.getItem("Layout"));
  var CalendarData = JSON.parse(sessionStorage.getItem("Calendar"));
  var AddinsData = JSON.parse(sessionStorage.getItem("Addins"));
  var DatesData = JSON.parse(sessionStorage.getItem("Dates"));
  var ReviewData = JSON.parse(sessionStorage.getItem("Review"));

  useEffect(() => {
    var defaultParameters = {
      pageCount: 2,
      maxPageCount: 242,
      price: 70,
      maxDate: endDate,
    };

    //getting session data
    var priceSessionData = JSON.parse(sessionStorage.getItem("Price"));
    const mergedData = { ...defaultParameters, ...priceSessionData };
    console.log("Price mergedData=>", mergedData);
    setPageCount(mergedData.pageCount);
    setMaxPageCount(mergedData.maxPageCount);
    setPrice(mergedData.price);
    setMaxDate(mergedData.maxDate);

    const formattedPrice = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
    setFormatedPrice(formattedPrice);

    const day = maxDate ? maxDate.getDate() : "";
    // Format the day with a leading zero if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const month = maxDate
      ? maxDate.toLocaleString("default", { month: "short" })
      : "";
    const year = maxDate ? maxDate.getFullYear() : "";
    setFormatedMaxDate(`${formattedDay} ${month} ${year}`);
  }, []);

  useEffect(() => {
    if (
      DatesData !== null &&
      DatesData.EventDate.endDate != null &&
      DatesData.EventDate.startDate != null &&
      DatesData.EventDate.endDate != "" &&
      DatesData.EventDate.startDate != ""
    ) {
      const { pagecount, price, maxdate } = CalculatePriceBoxParemeters();

      setPageCount(pagecount);
      if (updatePageCount != "") {
        updatePageCount(pagecount);
      }
      setPrice(price);
      setMaxDate(maxdate);

      const formattedPrice = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price);
      setFormatedPrice(formattedPrice);

      const day = maxdate ? maxdate.getDate() : "";
      // Format the day with a leading zero if needed
      const formattedDay = day < 10 ? `0${day}` : day;
      const month = maxdate
        ? maxdate.toLocaleString("default", { month: "short" })
        : "";
      const year = maxdate ? maxdate.getFullYear() : "";
      setFormatedMaxDate(`${formattedDay} ${month} ${year}`);

      //set parameters for button url and enable/disable it based on current route and session parameters
      //it will be use in handleNextPage()

      // Check if the "Dates" session item exists in sessionData
      var NewPriceData = sessionStorage.getItem("Price");
      if (NewPriceData != undefined) {
        var NewPriceData = JSON.parse(NewPriceData);
        // If it exists, update the "startDate" and "endDate"
        NewPriceData["pageCount"] = pagecount;
        NewPriceData["maxPageCount"] = maxPageCount;
        NewPriceData["price"] = price;
        NewPriceData["maxDate"] = maxdate;

        sessionStorage.setItem("Price", JSON.stringify(NewPriceData));
      } else {
        // If it doesn't exist, create a new "Dates" session item
        var PriceData = {
          pageCount: pagecount,
          maxPageCount: maxPageCount,
          price: price,
          maxDate: maxdate,
        };
        sessionStorage.setItem("Price", JSON.stringify(PriceData));
      }
    }
  }, [updatePriceBox]);

  function CalculatePriceBoxParemeters() {
    const MaxPages = 242;
    const today = new Date();
    var startdate = new Date(today);
    startdate.setDate(today.getDate() + 10);
    var enddate = new Date(startdate);
    var maxdate = new Date(today); // Clone today's date

    if (
      DatesData !== null &&
      DatesData.EventDate.endDate != null &&
      DatesData.EventDate.startDate != null
    ) {
      startdate = new Date(DatesData.EventDate.startDate);
      const start_date = new Date(startdate);
      if (DatesData.EventDate.maxLengthText === "Max length") {
        const end_date = calculateEndDateForPages(
          start_date,
          MaxPages,
          FirstPageData,
          LayoutData,
          CalendarData,
          AddinsData
        );
        console.log("End_date2=>", end_date);
        enddate = new Date(end_date);
        maxdate = new Date(end_date);
      } else {
        enddate = new Date(DatesData.EventDate.endDate);
        maxdate = new Date(enddate);
      }
    } else {
      //for default Max length
      var extraDays = 0;
      if (
        LayoutData !== undefined &&
        LayoutData !== null &&
        DatesData !== null &&
        DatesData.EventDate.maxLengthText === "Max length"
      ) {
        if (LayoutData.DailySinglePage !== undefined) {
          extraDays =
            242 -
            (Pages.FirstPages +
              Pages.EndPages +
              Pages.CalendarPages +
              Pages.AddinsPages);
        }
        if (LayoutData.DailyTwoPage !== undefined) {
          extraDays =
            0.5 *
            (242 -
              (Pages.FirstPages +
                Pages.EndPages +
                Pages.CalendarPages +
                Pages.AddinsPages));
        }
        if (LayoutData.WeeklyView !== undefined) {
          extraDays =
            3.5 *
            (242 -
              (Pages.FirstPages +
                Pages.EndPages +
                Pages.CalendarPages +
                Pages.AddinsPages));
        }
        console.log(Pages, extraDays);
      } else {
        //for default custom date
        if (
          LayoutData !== undefined &&
          LayoutData !== null &&
          DatesData === null
        ) {
          if (LayoutData.DailySinglePage !== undefined) {
            extraDays = 120;
          }
          if (LayoutData.DailyTwoPage !== undefined) {
            extraDays = 75;
          }
          if (LayoutData.WeeklyView !== undefined) {
            extraDays = 365;
          }
        }
      }

      //console.log("extraDays3=>",extraDays, startdate, enddate)
      enddate.setDate(startdate.getDate() + extraDays);
      maxdate = new Date(enddate);
    }

    const Pages = calculatePages(
      startdate,
      enddate,
      FirstPageData,
      LayoutData,
      CalendarData,
      AddinsData
    );
    const pagecount =
      Pages.FirstPages +
      Pages.EndPages +
      Pages.LayoutPages +
      Pages.CalendarPages +
      Pages.AddinsPages;
    console.log("Pricebox Pages=>", {
      FirstPages: Pages.FirstPages,
      EndPages: Pages.EndPages,
      LayoutPages: Pages.LayoutPages,
      CalendarPages: Pages.CalendarPages,
      AddinsPages: Pages.AddinsPages,
      PageCount: pagecount,
    });

    var price = 60 + 0.155 * pagecount;

    console.log(
      "updatePriceBox=>",
      updatePriceBox,
      DatesData.EventDate.startDate,
      DatesData.EventDate.endDate,
      pagecount,
      price,
      maxdate
    );
    return { pagecount, price, maxdate };
  }

  const calculateEndDateForPages = (
    sdate,
    maxpages,
    FirstPageData,
    LayoutData,
    CalendarData,
    AddinsData
  ) => {
    const MS_PER_DAY = 1; // milliseconds per day

    // Initial assumptions
    let edate = new Date(sdate); // Copy the start date

    let currentPages = 0;

    while (currentPages < maxpages) {
      // Increment the date by one day
      edate.setDate(edate.getDate() + MS_PER_DAY);

      // Recalculate pages using the updated edate
      const pagesData = calculatePages(
        sdate,
        edate,
        FirstPageData,
        LayoutData,
        CalendarData,
        AddinsData
      );
      //console.log("PagesData3=>", pagesData, edate);
      // Accumulate the pages
      currentPages =
        pagesData.FirstPages +
        pagesData.LayoutPages +
        pagesData.CalendarPages +
        pagesData.AddinsPages +
        pagesData.EndPages;
    }
    if (currentPages > maxpages) {
      edate.setDate(edate.getDate() - 1);
    }
    console.log("PriceBox=>", sdate, edate, currentPages);
    return edate;
  };

  const calculatePages = (
    sdate,
    edate,
    FirstPageData,
    LayoutData,
    CalendarData,
    AddinsData
  ) => {
    var FirstPages = FirstPageData !== undefined ? 1 : 0;

    var timeDiff = edate - sdate;
    timeDiff = timeDiff / (1000 * 60 * 60 * 24);

    //calculating days, weeks, months and quaters
    const Days = Math.round(timeDiff);
    const Weeks = Math.round(timeDiff / 7);
    const Months = Math.round(timeDiff / 30);
    const Quaters = Math.round(timeDiff / (30 * 3));
    //console.log("sdate:",sdate, " edata:",edate," Days:",Days," Weeks:",Weeks," Months=>",Months," Quaters=>",Quaters);

    var LayoutPages = 0;
    if (LayoutData !== undefined && LayoutData !== null) {
      if (LayoutData.DailySinglePage !== undefined) {
        LayoutPages = Days;
        if (Days % 2 == 1) LayoutPages++;
      }
      if (LayoutData.DailyTwoPage !== undefined) LayoutPages = Days * 2;
      if (LayoutData.WeeklyView !== undefined) LayoutPages = Weeks * 2;
    }

    var CalendarPages = 0;
    var onePage = 0;
    var monthPage = 0;
    var extraBackMonth = 0;
    if (CalendarData !== undefined && CalendarData !== null) {
      for (const category in CalendarData) {
        if (category == "yearlyTemplateSelected") {
          const items = CalendarData[category];
          items.forEach((item) => {
            if (item.optType == "Front of Planner") {
              onePage += item.count;
            }
            if (item.optType == "Back of Planner") {
              onePage += item.count;
            }
          });
        }
        if (category == "monthlyTemplateSelected") {
          const items = CalendarData[category];
          items.forEach((item) => {
            if (item.optType == "Front of Planner") {
              onePage += item.count * Months;
            }
            if (item.optType == "Back of Planner") {
              onePage += item.count * Months;
              extraBackMonth = 1;
            }
            if (item.optType == "Monthly") {
              monthPage += item.count * Months;
            }
          });
        }
      }

      // console.log("Calendarpages:", onePage, monthPage)
      onePage = onePage * 2;
      monthPage = monthPage * 2 + 2;
      if (
        extraBackMonth === 1 &&
        CalendarData["monthlyPlannerRadio"] == "246"
      ) {
        monthPage += 2 * 6; //for 6 months advance calanders
      }
      if (
        extraBackMonth === 1 &&
        CalendarData["monthlyPlannerRadio"] == "247"
      ) {
        monthPage += 2 * 12; //for 12 months advance calanders
      }
      CalendarPages = onePage + monthPage;
      //console.log("CalendarTotal:", CalendarPages,"=",onePage, monthPage)
    }

    var AddinsPages = 0;
    var onePage = 0;
    var weekPage = 0;
    var monthPage = 0;
    var quarterPage = 0;
    if (AddinsData !== undefined && AddinsData !== null) {
      for (const category in AddinsData) {
        const items = AddinsData[category];
        console.log("items si ", items);
        items?.forEach((item) => {
          if (
            item.optType == "Front of Planner" ||
            item.optType == "Back of Planner"
          ) {
            onePage += item.count;
          }
          if (item.optType == "Weekly") {
            weekPage += item.count;
          }
          if (item.optType == "Monthly") {
            monthPage += item.count;
          }
          if (item.optType == "Quarterly") {
            quarterPage += item.count;
          }
        });
      }
      // console.log("AddinPages:", onePage, weekPage, monthPage, quarterPage)
      onePage = onePage * 2;
      weekPage = weekPage * Weeks * 2;
      monthPage = monthPage * Months * 2;
      quarterPage = quarterPage * Quaters * 2;
      AddinsPages = onePage + weekPage + monthPage + quarterPage;
      // console.log("AddinTotal:", AddinsPages,"=",onePage, weekPage, monthPage, quarterPage)
    }

    const EndPages = 1;
    return {
      FirstPages: FirstPages,
      EndPages: EndPages,
      LayoutPages: LayoutPages,
      CalendarPages: CalendarPages,
      AddinsPages: AddinsPages,
    };
  };

  const handleBackPage = () => {
    const currentIndex = NavigateUrls.indexOf(currentRoute);
    var backIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    const backBtnUrl = NavigateUrls[backIndex];
    if (currentIndex > 0) {
      setBackBtnEnable(true);
      navigate(backBtnUrl);
    } else {
      setBackBtnEnable(false);
    }
  };

  const handleNextPage = () => {
    console.log("currentRoute=>", currentRoute);
    if (currentRoute == "/cover" && CoverData !== undefined) {
      setNextBtnEnable(true);
      navigate("/firstpage");
    }
    if (currentRoute == "/firstpage" && FirstPageData !== undefined) {
      setNextBtnEnable(true);
      addBtnFunc();
      //navigate('/layout');
    }
    if (currentRoute == "/layout" && LayoutData !== undefined) {
      setNextBtnEnable(true);
      navigate("/addins");
    }
    if (currentRoute == "/addins" && AddinsData !== undefined) {
      setNextBtnEnable(true);
      navigate("/dates");
    }
    if (
      currentRoute == "/dates" &&
      DatesData !== undefined &&
      pageCount <= maxPageCount
    ) {
      setNextBtnEnable(true);
      addBtnFunc();
    }
    if (
      currentRoute == "/review" &&
      ReviewData !== undefined &&
      ReviewData !== null &&
      ReviewData.TermsAgree == false
    ) {
      setNextBtnEnable(true);
      navigate("");
    }
    if (
      currentRoute == "/review" &&
      ReviewData !== undefined &&
      ReviewData !== null
    ) {
      setNextBtnEnable(true);
      addBtnFunc();
    }
  };

  return (
    <>
      <Box className="priceHoverBox draggable-popup">
        <Box className="priceInfo">
          <Box className="priceInfo1">
            <Box className="priceInfo1Sub">
              <Typography className="priceInfoText">Page Count</Typography>
              <Typography className="priceInfoBlackText">
                {pageCount}/{maxPageCount}
              </Typography>
            </Box>
            <Box className="priceInfo1Sub">
              <Typography className="priceInfoText">Max End Date</Typography>
              <Typography className="priceInfoBlackText">
                {formatedMaxDate}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="priceBtnContainer">
          <Box
            className={
              backBtnEnable ? "pricebtn back pointer" : "pricebtn back disabled"
            }
            onClick={handleBackPage}
          >
            <img src={rightArrow} className="backarrow" />
            <Typography mr={0.5}>Back</Typography>
          </Box>
          {currentRoute !== "/review" && (
            <Box
              className={
                nextBtnEnable ? "pricebtn pointer" : "pricebtn disabled"
              }
              onClick={handleNextPage}
            >
              <Typography mr={0.5}>Next</Typography>
              <img src={rightArrow} />
            </Box>
          )}
          {currentRoute == "/review" && (
            <Box
              className={
                nextBtnEnable
                  ? "pricebtn cart pointer"
                  : "pricebtn cart disabled"
              }
              onClick={handleNextPage}
            >
              <Typography mr={0.5}>Add To Cart</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
